import { Operator } from '../admin/CustomFilterForm/CustomFilterForm';

export const transformHintText = text => {
  if (!text) {
    return text;
  }

  const replacements = {
    [Operator.ADDITION]: '+',
    [Operator.SUBTRACTION]: '-',
    [Operator.WEIGHT]: 'Osakaal',
    [Operator.PERCENTAGE]: 'Osatähtsus (%)',
  };

  const regex = new RegExp(Object.values(Operator).join('|'), 'g');

  return text.replace(regex, match => replacements[match] || match);
};
