import { uniqBy } from 'lodash';
import React from 'react';
import { Button } from 'react-bulma-components';
import ReactSelect, { components } from 'react-select';
import ReactAsyncSelect from 'react-select/lib/Async';
import ReactCreatableSelect from 'react-select/lib/Creatable';
import { reactSelectTheme } from '../../assets/styles';
import { Translate } from '../../i18n/LanguageProvider';
import Checkbox from '../Checkbox';
import './Select.scss';
import { transformHintText } from '../../shared/string';

const Select = ({ isMulti, value = [], ...props }) => {
  const [input, setInput] = React.useState('');

  const onInputChange = (value, { action }) => {
    if (!isMulti || (isMulti && action !== 'set-value')) setInput(value);
  };

  return (
    <ReactSelect
      value={value}
      inputValue={input}
      onInputChange={onInputChange}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#F8F8F8',
          primary: 'black',
        },
      })}
      className="react-select"
      classNamePrefix="react-select"
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      hideSelectedOptions={false}
      placeholder={<Translate>components.Select.MenuList.placeholder</Translate>}
      components={{
        MenuList: isMulti ? MenuList : components.MenuList,
        Option: isMulti ? Option : components.Option,
      }}
      {...props}
    />
  );
};

const MenuList = props => {
  const {
    options,
    setValue,
    selectProps: { value, inputValue, filterOption, getOptionLabel, getOptionValue, selectAllBtn = true },
  } = props;

  const selectFilteredOptions = () =>
    setValue(
      uniqBy(
        [
          ...value,
          ...options.filter(o => filterOption({ label: getOptionLabel(o), value: getOptionValue(o) }, inputValue)),
        ],
        getOptionValue
      )
    );

  return (
    <>
      {selectAllBtn && (
        <Button type="button" style={{ margin: '6px 8px 0 8px', padding: '15px' }} onClick={selectFilteredOptions}>
          <Translate>components.Select.MenuList.selectAll</Translate>
        </Button>
      )}
      <components.MenuList {...props} />
    </>
  );
};

const Option = props => {
  const { selectOption, isSelected, label, data } = props;
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    selectOption(data);
  };
  return (
    <components.Option {...props}>
      <div onClick={handleClick} className="custom-option" title={transformHintText(data.hint) || ''}>
        <span>{label}</span>
        <Checkbox readOnly checked={isSelected} />
      </div>
    </components.Option>
  );
};

export const AsyncSelect = props => <ReactAsyncSelect theme={reactSelectTheme} {...props} />;
export const CreatableSelect = props => <ReactCreatableSelect theme={reactSelectTheme} {...props} />;

export default Select;
