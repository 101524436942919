import * as yup from 'yup';
import { Operator } from './CustomFilterForm';

export const CustomFilterProperty = {
  OPERATION: 'operation',
  DISPLAY_ET: 'displayNameEt',
  DISPLAY_EN: 'displayNameEn',
  DIMENSION_ONE: 'dimensionOne',
  SELECTION_ONE: 'selectionOne',
  DIMENSION_TWO: 'dimensionTwo',
  SELECTION_TWO: 'selectionTwo',
};

const CustomFilterSchema = yup.object().shape({
  [CustomFilterProperty.OPERATION]: yup.string().required('Tehte tüüp on kohustuslik'),
  [CustomFilterProperty.DISPLAY_ET]: yup.string().required('Nimi eesti k. on kohustuslik').max(400),
  [CustomFilterProperty.DISPLAY_EN]: yup.string().required('Nimi inglise k. on kohustuslik').max(400),
  [CustomFilterProperty.DIMENSION_ONE]: yup.object().required('Esimene dimensiooni valik on kohustuslik'),
  [CustomFilterProperty.SELECTION_ONE]: yup.object().required('Esimene väärtuse valik on kohustuslik'),
  [CustomFilterProperty.DIMENSION_TWO]: yup.object().when([CustomFilterProperty.OPERATION], (operation, schema) => {
    if (operation === Operator.WEIGHT || operation === Operator.PERCENTAGE) {
      return schema.required('Teine dimensiooni valik on kohustuslik');
    }
    return schema;
  }),
  [CustomFilterProperty.SELECTION_TWO]: yup.object().required('Teine väärtuse valik on kohustuslik'),
});

export default CustomFilterSchema;
