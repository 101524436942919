import React, { useState, useEffect } from 'react';
import { Columns } from 'react-bulma-components';
import Select, { CreatableSelect } from '../components/Select';
import {
  getISOWeeksInYear,
  addWeeks,
  isSameISOWeekYear,
  startOfISOWeekYear,
  getISOWeekYear,
  format,
  isEqual,
  startOfDay,
  isDate,
  addHours
} from 'date-fns';
import { et } from 'date-fns/locale';

const DatePicker = ({ name, value, period, onChange }) => {
  const parsedDate = value && new Date(value);
  const date = value && !isNaN(parsedDate) && parsedDate;

  return (
    <Columns>
      {period === 'QUARTER' && (
        <Columns.Column>
          <QuarterSelector name={name} date={date} onChange={onChange} />
        </Columns.Column>
      )}
      {period === 'MONTH' && (
        <Columns.Column>
          <MonthSelector name={name} date={date} onChange={onChange} />
        </Columns.Column>
      )}
      {period === 'WEEK' && date && (
        <Columns.Column>
          <WeekSelector name={name} date={date} onChange={onChange} />
        </Columns.Column>
      )}
      <Columns.Column>
        <YearSelector name={name} date={date} onChange={onChange} period={period} />
      </Columns.Column>
    </Columns>
  );
};

export default DatePicker;

const QuarterSelector = ({ name, date, onChange }) => {
  const quarter = date && !isNaN(date) ? Math.ceil((date.getMonth() + 1) / 3) - 1 : '';

  const onQuarterChange = (option, action) => {
    const newDate = makeDate(date);
    if (option) newDate.setMonth(option.value * 3);
    onChange(option ? newDate : '', action);
  };

  return (
    <DateSelector
      name={name}
      value={quarter}
      options={quarterOptions}
      placeholder="Vali kvartal"
      onChange={onQuarterChange}
    />
  );
};

const MonthSelector = ({ name, date, onChange }) => {
  const month = date && !isNaN(date) ? date.getMonth() : '';

  const onMonthChange = (option, action) => {
    const newDate = makeDate(date);
    if (option) newDate.setMonth(option.value);
    onChange(option ? newDate : null, action);
  };

  return (
    <DateSelector name={name} onChange={onMonthChange} options={monthOptions} value={month} placeholder="Vali kuu" />
  );
};

const WeekSelector = ({ name, date, onChange }) => {
  const [week, setWeek] = useState(date && !isNaN(date) ? makeDate(date) : '');
  const [weekOptions, setWeekOptions] = useState(getWeekOptions(getISOWeekYear(date)));

  useEffect(() => {
    setWeek(makeDate(date));
    if (!isSameISOWeekYear(week, date)) setWeekOptions(getWeekOptions(getISOWeekYear(date)));
  }, [date]);

  const onWeekChange = (option, action) => {
    const newDate = option ? option.value : '';
    onChange(option ? newDate : null, action);
  };

  return (
    <DateSelector name={name} onChange={onWeekChange} options={weekOptions} value={week} placeholder="Vali nädal" />
  );
};

const YearSelector = ({ name, date, onChange, period }) => {
  const [savedDate, setSavedDate] = useState(date ? date : null);
  const yearValue = savedDate ? (period === 'WEEK' ? getISOWeekYear(savedDate) : savedDate.getFullYear()) : '';
  const predefinedOption = yearOptions.find(option => option.value === yearValue);
  const newOption = date && {
    label: yearValue,
    value: yearValue
  };
  const options = newOption && !predefinedOption ? [...yearOptions, newOption] : yearOptions;
  const option = predefinedOption || newOption;

  useEffect(() => {
    setSavedDate(date);
  }, [date]);

  const onYearChange = (option, action) => {
    const newDate = makeDate(date);
    if (option) newDate.setFullYear(option.value);
    const newValue = option
      ? period === 'WEEK'
        ? addHours(startOfISOWeekYear(new Date(newDate.getFullYear(), 6, 5)), 3)
        : newDate
      : null;
    setSavedDate(newValue);
    onChange(newValue, action);
  };

  const onCreateOption = value => {
    const parsedValue = parseInt(value);

    if (!isNaN(parsedValue) && 1950 <= parsedValue && parsedValue <= 2050) {
      const newOption = { label: parsedValue, value: parsedValue };
      onYearChange(newOption, { name });
    }
  };

  return (
    <CreatableSelect
      name={name}
      onChange={onYearChange}
      onCreateOption={onCreateOption}
      placeholder="Vali aasta"
      className="react-select-container"
      menuPortalTarget={document.body}
      isClearable
      options={options}
      value={option}
      hideSelectedOptions={false}
      formatCreateLabel={value => `Lisa väärtus ${value}`}
    />
  );
};

const makeDate = date => (date ? new Date(date) : new Date());

const quarterOptions = [
  { label: '1. kvartal', value: 0 },
  { label: '2. kvartal', value: 1 },
  { label: '3. kvartal', value: 2 },
  { label: '4. kvartal', value: 3 }
];

const monthOptions = [
  { label: 'jaanuar', value: 0 },
  { label: 'veebruar', value: 1 },
  { label: 'märts', value: 2 },
  { label: 'aprill', value: 3 },
  { label: 'mai', value: 4 },
  { label: 'juuni', value: 5 },
  { label: 'juuli', value: 6 },
  { label: 'august', value: 7 },
  { label: 'september', value: 8 },
  { label: 'oktoober', value: 9 },
  { label: 'november', value: 10 },
  { label: 'detsember', value: 11 }
];

const yearOptions = (() => {
  const options = [];
  const startYear = 2010;
  const endYear = new Date().getFullYear() + 4;

  for (let i = startYear; i <= endYear; i++) {
    options.push({
      label: i,
      value: i
    });
  }

  return options;
})();

const getWeekOptions = year => {
  let tempDate = addHours(startOfISOWeekYear(new Date(year, 6, 5)), 3);
  const numberOfWeeks = getISOWeeksInYear(tempDate);

  const weekOptions = [];
  let currentWeek = 1;

  while (currentWeek <= numberOfWeeks) {
    weekOptions.push({
      label: `nädal ${currentWeek}, algusega ${format(tempDate, 'dd.MM.yyyy', { locale: et })}`,
      value: tempDate
    });
    tempDate = addWeeks(tempDate, 1);
    currentWeek = currentWeek + 1;
  }

  return weekOptions;
};

const DateSelector = ({ value, options, ...props }) => (
  <Select
    className="react-select-container"
    menuPortalTarget={document.body}
    options={options}
    isClearable
    value={options.find(option =>
      isDate(value) && isDate(option.value)
        ? isEqual(startOfDay(option.value), startOfDay(value))
        : option.value === value
    )}
    hideSelectedOptions={false}
    {...props}
  />
);
