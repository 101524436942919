import React, { useState } from 'react';
import { Button } from 'react-bulma-components';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { createYupValidator } from '../../shared/form';
import CustomFilterSchema, { CustomFilterProperty } from './CustomFilterSchema';
import CustomFilterFormBody from './CustomFilterFormBody/CustomFilterFormBody';

const validate = createYupValidator(CustomFilterSchema);

export const Operator = {
  ADDITION: 'ADDITION',
  SUBTRACTION: 'SUBTRACTION',
  WEIGHT: 'WEIGHT',
  PERCENTAGE: 'PERCENTAGE',
};

const CustomFilterForm = ({ dimensions, onSubmit, onCancel }) => {
  const [previousDimensionOneStatId, setPreviousDimensionOneStatId] = useState(null);

  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={validate}
      render={({ form, hasValidationErrors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <CustomFilterFormBody dimensions={dimensions} />
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) => {
              const operationValue = values[CustomFilterProperty.OPERATION];
              const isOneDimensionalOperation =
                operationValue === Operator.ADDITION || operationValue === Operator.SUBTRACTION;
              const dimensionOneValue = values[CustomFilterProperty.DIMENSION_ONE];
              const dimensionTwoValue = values[CustomFilterProperty.DIMENSION_TWO];
              const selectionOneValue = values[CustomFilterProperty.SELECTION_ONE];
              const selectionTwoValue = values[CustomFilterProperty.SELECTION_TWO];

              if (isOneDimensionalOperation) {
                if (Boolean(dimensionTwoValue)) {
                  form.change(CustomFilterProperty.DIMENSION_TWO);
                }
                if (Boolean(dimensionTwoValue) && Boolean(selectionTwoValue)) {
                  form.change(CustomFilterProperty.SELECTION_TWO);
                }
              }
              if (!isOneDimensionalOperation && !Boolean(dimensionTwoValue) && Boolean(selectionTwoValue)) {
                form.change(CustomFilterProperty.SELECTION_TWO);
              }
              if (dimensionOneValue?.statId !== previousDimensionOneStatId && Boolean(selectionOneValue)) {
                form.change(CustomFilterProperty.SELECTION_ONE);
                if (isOneDimensionalOperation) {
                  form.change(CustomFilterProperty.SELECTION_TWO);
                }
              }

              setPreviousDimensionOneStatId(values.dimensionOne?.statId ?? null);
            }}
          />
          <div className="buttons justify-flex-end mt-4">
            <Button onClick={onCancel} className="is-secondary">
              Katkesta
            </Button>
            <Button type="submit" disabled={hasValidationErrors} className="is-primary">
              Loo uus filtri väärtus
            </Button>
          </div>
        </form>
      )}
    />
  );
};

export default CustomFilterForm;
