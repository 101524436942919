import React from 'react';
import { Columns } from 'react-bulma-components';
import { InputField, LabeledControl, PrimitiveSelectField, SelectField, useFieldValue } from '../../../shared/form';
import { CustomFilterProperty } from '../CustomFilterSchema';
import { COLOR_THEME } from '../../../assets/styles';
import { Operator } from '../CustomFilterForm';
import customFilterValueStyles from '../../../components/Select/FilterValueStyling';

const CustomFilterFormBody = ({ dimensions }) => {
  const operationFieldValue = useFieldValue(CustomFilterProperty.OPERATION);
  const usesOneDimension =
    !operationFieldValue || operationFieldValue === Operator.ADDITION || operationFieldValue === Operator.SUBTRACTION;
  const dimensionOneValues = useFieldValue(CustomFilterProperty.DIMENSION_ONE).values ?? [];
  const dimensionTwoValues = useFieldValue(CustomFilterProperty.DIMENSION_TWO).values ?? [];
  const dimensionOneValue = useFieldValue(CustomFilterProperty.DIMENSION_ONE);
  const dimensionTwoValue = useFieldValue(CustomFilterProperty.DIMENSION_TWO);
  const selectionOneValue = useFieldValue(CustomFilterProperty.SELECTION_ONE);
  const selectionTwoValue = useFieldValue(CustomFilterProperty.SELECTION_TWO);

  const getFilteredSelectionValue = (array, filterValue) => {
    if (!filterValue) {
      return array;
    }

    return array.filter(item => item.statId !== filterValue.statId);
  };

  const filteredFirstDimensionValues = dimensions;
  const filteredSecondDimensionValues = dimensions;
  const filterDimensionSelectionValues = dimensionOneValue?.statId === dimensionTwoValue?.statId;

  const dimensionOneFilteredValues = filterDimensionSelectionValues
    ? getFilteredSelectionValue(dimensionOneValues, selectionTwoValue)
    : dimensionOneValues;

  const dimensionTwoFilteredValues = usesOneDimension
    ? getFilteredSelectionValue(
        dimensionOneValues,
        selectionOneValue.statId !== selectionTwoValue?.statId ? selectionOneValue : null
      )
    : filterDimensionSelectionValues
    ? getFilteredSelectionValue(dimensionTwoValues, selectionOneValue)
    : dimensionTwoValues;

  const operationOptions = [
    { label: 'Liitmine', value: Operator.ADDITION },
    { label: 'Lahutamine', value: Operator.SUBTRACTION },
    { label: 'Osakaal', value: Operator.WEIGHT },
    { label: 'Osatähtsus (%)', value: Operator.PERCENTAGE },
  ];

  const getOperatorValue = () => {
    switch (operationFieldValue) {
      case Operator.ADDITION:
        return '+';
      case Operator.SUBTRACTION:
        return '-';
      case Operator.WEIGHT:
        return 'osakaal';
      case Operator.PERCENTAGE:
        return 'osatähtsus (%)';
    }
  };

  const renderSelectionOne = () => {
    return selectionOneValue ? (
      <span className="has-text-weight-semibold">
        {selectionOneValue.valueEt} ({selectionOneValue.valueEn})
      </span>
    ) : (
      '...'
    );
  };

  const renderSelectionTwo = () => {
    if (!selectionTwoValue) {
      return '...';
    }
    if (usesOneDimension) {
      return (
        <span className="has-text-weight-semibold">
          {selectionTwoValue.valueEt} ({selectionTwoValue.valueEn})
        </span>
      );
    }

    return (
      <span>
        <span className="has-text-weight-semibold">
          {dimensionTwoValue.nameEt} ({dimensionTwoValue.nameEn})
        </span>
        <span> väärtuse </span>
        <span className="has-text-weight-semibold">
          {selectionTwoValue.valueEt} ({selectionTwoValue.valueEn})
        </span>
        <span> suhtes</span>
      </span>
    );
  };

  return (
    <div>
      <Columns>
        <Columns.Column size={12}>
          <LabeledControl
            label="Tehte tüüp"
            name={CustomFilterProperty.OPERATION}
            component={PrimitiveSelectField}
            options={operationOptions}
            getOptionLabel={o => o.label}
            getOptionValue={o => o.value}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 1000 }),
            }}
          />
        </Columns.Column>
        <>
          <Columns.Column size="half">
            <LabeledControl name={CustomFilterProperty.DISPLAY_ET} label="Nimi eesti k" component={InputField} />
          </Columns.Column>
          <Columns.Column size="half">
            <LabeledControl name={CustomFilterProperty.DISPLAY_EN} label="Nimi inglise k" component={InputField} />
          </Columns.Column>
        </>
        <>
          <Columns.Column size={usesOneDimension ? 12 : 'half'}>
            <LabeledControl
              label="Esimene dimensioon"
              name={CustomFilterProperty.DIMENSION_ONE}
              component={SelectField}
              options={filteredFirstDimensionValues}
              getOptionValue={o => o}
              getOptionLabel={o => `${o.nameEt} (${o.nameEn})`}
              menuPortalTarget={document.body}
              disabled
              styles={{
                menuPortal: base => ({ ...base, zIndex: 1000 }),
              }}
            />
          </Columns.Column>
          {!usesOneDimension && (
            <Columns.Column size="half">
              <LabeledControl
                label="Teine dimensioon"
                name={CustomFilterProperty.DIMENSION_TWO}
                component={SelectField}
                options={filteredSecondDimensionValues}
                getOptionValue={o => o}
                getOptionLabel={o => `${o.nameEt} (${o.nameEn})`}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 1000 }),
                }}
              />
            </Columns.Column>
          )}
        </>
        <>
          <Columns.Column size="half">
            <LabeledControl
              label={`Esimene filtri väärtus${usesOneDimension ? '' : ' (Jagatav)'}`}
              name={CustomFilterProperty.SELECTION_ONE}
              component={SelectField}
              options={dimensionOneFilteredValues}
              getOptionValue={o => o}
              getOptionLabel={o => `${o.valueEt} (${o.valueEn})`}
              isDisabled={dimensionOneValues.length === 0}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 1000 }),
                ...customFilterValueStyles,
              }}
            />
          </Columns.Column>
          <Columns.Column size="half">
            <LabeledControl
              label={`Teine filtri väärtus${usesOneDimension ? '' : ' (Jagaja)'}`}
              name={CustomFilterProperty.SELECTION_TWO}
              component={SelectField}
              options={dimensionTwoFilteredValues}
              getOptionValue={o => o}
              getOptionLabel={o => `${o.valueEt} (${o.valueEn})`}
              isDisabled={usesOneDimension ? dimensionOneValues.length === 0 : dimensionTwoValues.length === 0}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 1000 }),
                ...customFilterValueStyles,
              }}
            />
          </Columns.Column>
        </>
      </Columns>
      {operationFieldValue && (
        <div className="mt-4">
          <div className="filter-value-example-title">Tehte näidis</div>
          <div className={`filter-value-example ${usesOneDimension && 'one-dimensional'}`}>
            <div>{renderSelectionOne()}</div>
            <div>{getOperatorValue()}</div>
            <div>{renderSelectionTwo()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomFilterFormBody;
