import { ARRAY_ERROR } from 'final-form';
import * as _ from 'lodash';
import React from 'react';
import { Form } from 'react-bulma-components';
import { Field, useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import * as yup from 'yup';
import DatePicker from '../admin/DatePicker';
import Checkbox from '../components/Checkbox';
import Select from '../components/Select';

export const createYupValidator = schema => values =>
  schema
    .validate(values, { abortEarly: false })
    .then(() => {})
    .catch(error => {
      const formErrors = {};

      error.inner.forEach(innerError => createEmptyArrays(formErrors, innerError.path, schema));

      error.inner
        .filter(innerError => innerError.type !== ARRAY_ERROR)
        .forEach(innerError => _.set(formErrors, innerError.path, innerError.message));

      error.inner
        .filter(innerError => innerError.type === ARRAY_ERROR)
        .forEach(innerError => {
          const originalValue = _.get(formErrors, innerError.path);
          const value = originalValue || [];

          if (!(originalValue instanceof Array)) _.set(formErrors, innerError.path, value);

          value[ARRAY_ERROR] = value[ARRAY_ERROR] instanceof Array ? value[ARRAY_ERROR] : [];
          value[ARRAY_ERROR].push(innerError.message);
        });

      return formErrors;
    });

const createEmptyArrays = (object, pathString, schema) =>
  _.toPath(pathString)
    .map((element, index, array) => array.slice(0, index))
    .map(path => ({
      path,
      schema: yup.reach(schema, path.join('.'))
    }))
    .filter(({ schema }) => schema instanceof yup.array)
    .forEach(({ path }) => {
      const array = [];
      array[ARRAY_ERROR] = [];
      _.set(object, path, array);
    });

export const useFieldValue = name =>
  useField(name, {
    subscription: { value: true }
  }).input.value;

export const useFieldArrayValue = name =>
  useFieldArray(name, {
    subscription: { value: true }
  }).input.value;

export const LabeledControl = ({ label, children, render, component, ...props }) => (
  <Form.Field>
    <Form.Label>{label}</Form.Label>
    <Field {...props}>
      {innerProps => (
        <>
          <Form.Control>
            {render && render(innerProps)}
            {children && typeof children === 'function' && children(innerProps)}
            {component && React.createElement(component, innerProps, children)}
          </Form.Control>
          {innerProps.meta.error && <Form.Help color="danger">{innerProps.meta.error}</Form.Help>}
        </>
      )}
    </Field>
  </Form.Field>
);

export const TextareaField = ({ input, meta, ...props }) => <Form.Textarea rows={2} {...input} {...props} />;
export const InputField = ({ input: { value, ...input }, meta, ...props }) => (
  <Form.Input {...input} value={String(value)} {...props} />
);

export const RadioField = ({ input, meta, ...props }) => <Form.Radio {...input} {...props} />;
export const CheckboxField = ({ input: { value, ...input }, meta, ...props }) => (
  <Checkbox checked={value} {...input} {...props} />
);

export const DatePickerField = ({ input, meta, ...props }) => <DatePicker {...input} {...props} />;

export const SelectField = ({ input, meta, ...props }) => <Select {...input} {...props} />;
export const PrimitiveSelectField = ({
  input,
  meta,
  options = [],
  getOptionLabel = o => o.label,
  getOptionValue = o => o.value,
  ...props
}) => (
  <Select
    {...input}
    value={options.filter(o => getOptionValue(o) === input.value)}
    onChange={o => input.onChange(o ? getOptionValue(o) : o)}
    options={options}
    getOptionLabel={getOptionLabel}
    getOptionValue={getOptionValue}
    {...props}
  />
);

const useForm = (value = {}, onFormChange = () => {}, invalidRules = {}) => {
  const getColor = name => invalidRules[name] && 'danger';
  const getHelp = name => {
    const rules = invalidRules[name];
    return rules && rules[0].message;
  };

  /* Form elements */

  const text = (name, props = {}) => (
    <>
      <Form.Control>
        <Form.Input
          autoComplete="off"
          name={name}
          value={value[name]}
          onChange={onChange}
          color={getColor(name)}
          {...props}
        />
      </Form.Control>
      {getHelp(name) && <Form.Help color={getColor(name)}>{getHelp(name)}</Form.Help>}
    </>
  );

  const number = (name, props = {}) => (
    <>
      <div className="control">
        <input
          onInput={evt => evt.target.validity.valid || (evt.target.value = '')}
          className="input"
          type="number"
          autoComplete="off"
          name={name}
          value={value[name]}
          onChange={onNumberChange}
          {...props}
        />
      </div>
      {getHelp(name) && <Form.Help color={getColor(name)}>{getHelp(name)}</Form.Help>}
    </>
  );

  const textArea = (name, props = {}) => (
    <>
      <Form.Control>
        <Form.Textarea name={name} rows={2} value={value[name]} onChange={onChange} {...props} />
      </Form.Control>
    </>
  );

  const checkbox = (name, { label, value: checkboxValue, ...props } = {}) => (
    <Form.Control>
      <Checkbox
        name={name}
        value={checkboxValue}
        onChange={onChange}
        checked={value[name] === checkboxValue}
        {...props}
      >
        {label}
      </Checkbox>
    </Form.Control>
  );

  const toggle = (name, { label, ...props }) =>
    checkbox(name, { label, checked: value[name], onChange: onCheckboxChange, ...props });

  const select = (name, props = {}) => (
    <>
      <Select
        className="react-select-container"
        menuPortalTarget={document.body}
        name={name}
        value={value[name]}
        onChange={onSelectChange}
        hideSelectedOptions={false}
        {...props}
      />
      {getHelp(name) && <Form.Help color={getColor(name)}>{getHelp(name)}</Form.Help>}
    </>
  );

  const file = (name, props = {}) => (
    <Form.Control>
      <input name={name} type="file" onChange={onFileInputChange} {...props} />
    </Form.Control>
  );

  const date = (name, period) => (
    <>
      <DatePicker name={name} value={value[name]} onChange={onSelectChange} period={period} />
      {getHelp(name) && <Form.Help color={getColor(name)}>{getHelp(name)}</Form.Help>}
    </>
  );

  /* onChange handlers */

  const onChange = ({ target: { name, value } }) =>
    onFormChange(prevState => ({
      ...prevState,
      [name]: value
    }));

  const onCheckboxChange = ({ target: { name, checked } }) =>
    onFormChange(prevState => ({
      ...prevState,
      [name]: checked
    }));

  const onNumberChange = ({ target: { name, value } }) =>
    onFormChange(prevState => ({
      ...prevState,
      [name]: value.length > 0 ? parseInt(value) : value
    }));

  const onSelectChange = (selectValue, action) =>
    onFormChange(prevState => ({
      ...prevState,
      [action.name]: selectValue
    }));

  const onFileInputChange = ({ target: { name, files } }) =>
    onFormChange(prevState => ({
      ...prevState,
      [name]: [files[0]]
    }));

  return { text, textArea, checkbox, toggle, date, number, file, select, onChange, onSelectChange };
};

export const useValidator = (value, rules, externalErrors = []) => {
  const invalidRules = rules.filter(rule => !rule.predicate(value));
  const isValid = invalidRules.length === 0;
  const invalidRulesGroupedByName = _.groupBy([...invalidRules, ...externalErrors], 'name');

  return [isValid, invalidRulesGroupedByName];
};

export default useForm;
