const circleDotStyles = {
  transform: 'translateY(-50%)',
  display: 'inline-block',
  backgroundColor: '#4DC14D',
  position: 'absolute',
  borderRadius: '50%',
  content: '""',
  height: '8px',
  width: '8px',
  left: '7px',
  top: '50%',
};

const customFilterValueStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data }) => {
    return {
      ...styles,
      paddingLeft: '20px',
      position: 'relative',
      '::before': data?.custom ? circleDotStyles : null,
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      paddingLeft: data?.custom ? '16px' : null,
      position: 'relative',
      '::before': data?.custom ? circleDotStyles : null,
    };
  },
};

export default customFilterValueStyles;
